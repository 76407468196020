<template>
  <div>
     <br>
     <br>
    <el-row>
     
       <div style="float: right;">
          <el-select v-model="controllerId" :placeholder="$t('ChargingStationS')"  size="small" class="filter-item-mid" width="140px" clearable>
        <el-option v-for="item in controllerOption" :key="item.controlId"
                  :label="item.stationName + ' (控制器:' + item.controlId + ')'" :value="item.controlId" />                
      </el-select>

                                        <!--替換:選擇設備(電表)-->
      <el-select v-model="deviceIds" placeholder="選擇電表" 
         clearable size="small" class="filter-item-mid" width="140px" >
        <el-option v-for="item in devOption" :key="item.devId"
                  :label="item.devName + ' (id:' + item.devId + ')'" :value="item.devId" />
      </el-select>
      <el-button
       style="float: right; margin: 0px"
       size="small"
       type="primary"
       icon="el-icon-search"
       @click="searchSetting()"
      
     >
       查詢
     </el-button>
      </div>
     </el-row>
     <el-row>
     <el-divider content-position="right" >
      <span  v-show="isShowTotalAmount" style="font-weight: bold; font-size: 1.2em;"> {{ this.totalAmount }}</span>
    </el-divider>
     </el-row>
     <br>
     <br>
     <el-row>
     <!-- table -->
     <el-table
       :data="recordList" style="width: 100%;"
       :cell-style="{padding: '0', height: '40px'}"
       @sort-change="tableSortChange"
     >
       <el-table-column align="center" prop="name" label="充電樁名稱"  />
       <el-table-column align="center" prop="ocpp_sc_max_value" label="電流上限最大值(A)"   />
       <el-table-column align="center" prop="ocpp_sc_min_value" label="電流上限最小值(A)"   />
        <el-table-column align="center" prop="ocpp_sc_set_value" label="目前充電樁電流上限設定值(A)" />
     </el-table>

     <!--分頁組件-->
     <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="size" @pagination="searchRecord('2')" :page-sizes="[10]"/>
     </el-row>

     
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import { mixins } from '@/views/common/mixins.js';

/* 每隔10min刷新出jwt token*/
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import * as main from "@/utils/main";
import {apiUrl} from "@/utils/main";
const authUrl = main.authUrl();
const refreshTokenUrl = authUrl + "refreshToken";
const refreshTokenInterval = 10*60*1000;     // 10分鐘

export default {
name: 'metercontract',
mixins: [mixins],
data() {
  return {
    controllerId    : undefined,
    controllerIdList: '',//用以紀錄全部的ID
    controllerOption: undefined,
    meterContract:undefined,
    strURL:undefined,
   //deviceIds       : [], //因應複選 故要用陣列
    contractId:undefined, 
    deviceIds       : "", 
    devOption       : undefined,   

    //新增契約的id
   // contractName: undefined,
   // dataRange: [],
    amount: undefined,

    total:undefined,
    paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
    serviceId: undefined,
    recordList:[],
    historyList:[],
    isShowTotalAmount: false,
    totalAmount:'',
    ocppScType:undefined,
   // isShowAdd:false,
    //dialogSettingVisible: false,
    listQuery: { 
              //serviceId: this.$store.state.currentAccount.id,  
              serviceId: this.$store.state.currentAccount.serviceInfo.id,
              deviceIds  :  undefined,  
              sampleRate :  undefined, //undefined
              startDate  :  this.$moment().subtract(40, "days").format("YYYY-MM-DD"),//30天前,
              endDate    :  this.$moment().subtract(30, "days").format("YYYY-MM-DD"),//15天前
              page       : 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
              size       : 10,
          },
  };
},
watch: {
  paginationSelectedPage: {
    handler: function(newValue) {
      this.listQuery.page = newValue - 1
    }
  },
  'controllerId': function (val) {
    this.listQuery.page = 0;
    this.listQuery.deviceIds = undefined;
    //this.resetPage(this.getList);
    //this.listAll= [];
    //this.listSum= null;
    this.deviceIds = [];
    this.devOption=null;
    /* 若充電站(控制器)有被選擇,則才會找該充電站(控制器)所屬的設備電表,沒被選擇則stationId為0 */
    if( val != null && val != undefined && val > 0 ) {
      console.log("GET:/api/v1/metercontract/deviceidList?controlerId="+val);
      this.axios.get("/api/v1/metercontract/deviceidList?controlerId="+val).then(
          res => {
          console.log("Response:deviceidList [res.data]",res.data);
          this.devOption=res.data;
          
          //this.controllerIdList=res.data.controlId
          }
          );
    }
   }, 
   
},
async created() {
  this.getStationAndControllerByServiceId();
},
beforeDestroy() {
  
},
mounted() {
  window.onresize = () => {
    return (() => {
      this.dialogWidth = this.setDialogWidth()
    })()
  }
},
methods: {
  getStationAndControllerByServiceId() { 
    console.log("GET:/api/v1/metercontract/controleridList?serviceId="+this.listQuery.serviceId);
    this.axios.get("/api/v1/metercontract/controleridList?serviceId="+this.listQuery.serviceId).then(
        res => {
        console.log("Response:/api/v1/metercontract/controleridList [res.data]",res.data);
        this.controllerOption=res.data; 
        
          for(var i=0;i<res.data.length;i++)
          {

          if(this.controllerIdList=='')
            this.controllerIdList=res.data[i].controlId
          else
            this.controllerIdList=this.controllerIdList+',' + res.data[i].controlId       

          }
    
          console.log("this.controllerIdList",this.controllerIdList);
        }
       );
  }, 
  


  searchSetting(){
    console.log("searchSetting");

    if(this.controllerId==undefined)  //如果都沒選
    {
      //this.strURL="/api/v1/metercontract/contractdeviceidList?controlerId="+this.controllerIdList
      this.$message({ message: '請選擇控制器', type: 'warning' })
    }
    else
    {
      if(this.deviceIds=="")//如果只選控制器
      {
        this.$message({ message: '請選擇電錶', type: 'warning' })
       }
    }

    
    this.strURL="/api/v1/schecharge/dynamic/meter/"+this.deviceIds
   
    this.axios.get(this.strURL).then(
        res => {
        console.log("dynamiclist",res.data);
        this.totalAmount='電錶:'+this.deviceIds+'  容量:'+ res.data.ocppScMaxValue +res.data.ocppScType
        this.isShowTotalAmount=true
        //this.ocppScType=res.data.ocppScType
        }
       );


    this.strURL="/api/v1/schecharge/dynamiclist/"+this.deviceIds
   
    this.axios.get(this.strURL).then(
        res => {
        console.log("dynamiclist",res.data);
        this.recordList =res.data; 
        }
       );
  },

}

}
</script>

<style lang="scss" scoped>
.checkbox {
zoom:100%;
}
.alert {
zoom:100%;
}


.data-label {
width: 100px; display: inline-block;
}
.underline{
    text-decoration: underline;
    font-size: 15px;
    color: blue
  }
</style>
